.Home .lander {
  /* padding: 80px 0; */
  text-align: center;
}

.Home .lander p {
  color: #999;
}

.Home button {
  margin: 0px 7px;
}

.Home button svg {
  margin-right: 10px;
}

.Home form {
  margin-bottom: 20px;
}
